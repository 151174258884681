<template>
  <div
    :class="'agroup-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/member_bg.jpg')" />
      <div class="base-info">
        <van-image
          round
          width="20vw"
          height="20vw"
          :src="agentInfo.AgentFace ? agentInfo.AgentFace : defaultFace"
          fit="cover"
        />
        <div class="info">
          <span class="name">{{ agentInfo.AgentName }}</span>
          <span class="item">ID: {{ agentInfo.AgentCode }}</span>
          <span class="item" @click="onCopy(agentInfo.InviteCode)"
            >{{ PAGELANG.邀请码 }}: {{ agentInfo.InviteCode }}</span
          >
        </div>
      </div>
    </div>

    <van-cell-group inset>
      <van-cell
        :title="PAGELANG.一级代理"
        icon="aog iconfont icon-tuandui2"
        is-link
        :value="lv1count + PAGELANG.人"
        to="agentgroup/list?level=1"
      />
      <van-cell
        :title="PAGELANG.二级代理"
        icon="aog iconfont icon-tuandui"
        is-link
        :value="lv2count + PAGELANG.人"
        to="agentgroup/list?level=2"
      />
      <van-cell
        :title="PAGELANG.三级代理"
        icon="aog iconfont icon-tuandui1"
        is-link
        :value="lv3count + PAGELANG.人"
        to="agentgroup/list?level=3"
      />
    </van-cell-group>

    <!--<van-cell-group style="margin-top: 16px" inset>
      <van-cell
        :title="PAGELANG.分销商"
        icon="aog iconfont icon-tuandui3"
        is-link
        :value="districount + PAGELANG.人"
        to="agentgroup/distributors"
      />
      <van-cell
        :title="PAGELANG.零售商"
        icon="aog iconfont icon-ziliao-01"
        is-link
        :value="retailcount + PAGELANG.人"
        to="agentgroup/retailers"
      />
      <van-cell
        :title="PAGELANG.审核中心"
        icon="aog iconfont icon-shenhe"
        is-link
        :value="PAGELANG.待审 + ': ' + auditcount + PAGELANG.人"
        to="agentgroup/commits"
      />
    </van-cell-group>-->
  </div>

  <div class="pc-my-page">
    <MyMenu :agentInfo="agentInfo" pagename="agentgroup" />
    <div class="main">
      <div class="credit-info">
        <router-link class="item" to="agentgroup/list?level=1">
          <span class="num price">{{ lv1count + PAGELANG.人 }}</span>
          {{ PAGELANG.一级代理 }}
        </router-link>
        <router-link class="item" to="agentgroup/list?level=2">
          <span class="num price">{{ lv2count + PAGELANG.人 }}</span>
          {{ PAGELANG.二级代理 }}
        </router-link>
        <router-link class="item" to="agentgroup/list?level=3">
          <span class="num price">{{ lv3count + PAGELANG.人 }}</span>
          {{ PAGELANG.三级代理 }}
        </router-link>
      </div>
    </div>
  </div>
</template>
  
<script>
import { getCookie } from "../util";
import { showLoadingToast, showToast, closeToast } from "vant";
import MyMenu from "../components/MyMenu.vue";

export default {
  components: {
    MyMenu,
  },
  data() {
    return {
      agentInfo: [],
      defaultFace: require("@/assets/images/default_face.png"),
      lv1count: 0,
      lv2count: 0,
      lv3count: 0,
      districount: 0,
      retailcount: 0,
      auditcount: 0,
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.agentgroup;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;

    this.init();
  },
  methods: {
    init() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .get(this.actions.getaglvinfo)
        .then(({ data }) => {
          closeToast();
          let {
            retcode,
            lv1count,
            lv2count,
            lv3count,
            agentinfo,
            districount,
            retailcount,
            auditcount,
          } = data;

          if (retcode == "OK") {
            this.lv1count = lv1count;
            this.lv2count = lv2count;
            this.lv3count = lv3count;
            this.agentInfo = agentinfo;

            this.districount = districount;
            this.retailcount = retailcount;
            this.auditcount = auditcount;
          } else {
            showToast({
              message:
                retcode == "CHKLOINFAILED"
                  ? this.SYSLANG["您还没有登录"]
                  : this.SYSLANG["xitongfanmang"],
              forbidClick: true,
              onClose: () => {
                if (retcode == "CHKLOINFAILED") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          console.log("views.AgentGroup.init.error", error);
          closeToast();
          this.init();
        });
    },
  },
};
</script>

<style scoped>
@media (max-width: 678px) {
  .agroup-page {
    width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 16px;
    background-color: #f7f7f9;
    --aog-credit-menu-bottom-margin: 10px;
    --van-cell-group-inset-padding: 0;
    --van-cell-right-icon-color: #666;
  }

  .agroup-page .header {
    position: relative;
    line-height: 0;
    margin-bottom: 16px;
  }

  .agroup-page .header .member-bg {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .agroup-page .header .base-info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 4vw 15vw 4vw;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .agroup-page .header .base-info .info {
    width: 0;
    padding-left: 3vw;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 26px;
  }

  .agroup-page .header .base-info .info .name {
    font-size: 18px;
    color: #fff;
  }

  .agroup-page .header .base-info .info .item {
    background-color: var(--van-card-price-color);
    color: #fff;
    display: inline-block;
    padding: 0 10px;
    line-height: 24px;
    font-size: 13px;
    margin: 3px 6px 3px 0;
    border-radius: 6px;
  }

  >>> .van-icon-aog {
    color: var(--van-card-price-color);
  }
}

@media (min-width: 679px) {
  .agroup-page {
    display: none;
  }

  .pc-my-page .main {
    padding: 36px 30px;
  }

  .pc-my-page .main .credit-info .item {
    cursor: pointer;
  }
}
</style>